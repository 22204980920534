import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title }) {
  
  const initial_data_query = useStaticQuery(graphql`
    query {
      wpgraphql {
        pages(where: { title: "" }) {
          nodes {
            uri
            slug
            seo {
              title
              focuskw
              canonical
              metaDesc
            }
          }
        }
      }
    }
  `)

  let initial_state_data = initial_data_query.wpgraphql;
  const [existingData, updateData] = useState(initial_state_data);

  useEffect(() => {
    let curr_api = process.env.GATSBY_API_URL

    fetch(`${curr_api}/graphql`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: `
        {
          pages(where: {title: "${title}"}) {
            nodes {
              uri
              slug
              seo {
                title
                focuskw
                canonical
                metaDesc
              }
            }
          } 
        }
        `,
      }),
    })
    .then(res => res.json())
    .then(data => updateData(data.data))
    .catch(error => console.log(`Some Error Occurred! The Error :${error}`))
  }, [title])

  let metaTag;
  // console.log(existingData.pages);
  metaTag = existingData.pages.nodes[0].seo;

  const metadata = [
    {
      name: "Title",
      content: metaTag.title,
    },
    {
      property: "description",
      content: metaTag.metaDesc,
    },
    {
      property: "Canonical",
      content: metaTag.canonical,
    },
    {
      property: "Keywords",
      content: metaTag.focuskw,
    },
  ]
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={metadata}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO;
